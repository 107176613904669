import { Heading, Link, Paragraph, ThemeDefinition } from '@loveholidays/design-system';
import React, { FC } from 'react';

import { HastNode } from '@Core/types';
import { hastToReact } from '@UX/Hast/Hast';

export const tooltipHastContentTitleElementName = 'h3';

type TooltipFilterContentProps = {
  content: HastNode;
  className?: string;
};

export const TooltipHastContent: FC<TooltipFilterContentProps> = ({ content, className }) => (
  <div className={className}>
    {hastToReact(content, {
      [tooltipHastContentTitleElementName]: {
        Component: Heading,
        Props: {
          as: 'h3',
          variant: 'mediumbold',
          sx: {
            marginTop: 0,
            marginBottom: 'xs',
          },
        },
      },
      p: {
        Component: Paragraph,
        Props: {
          variant: 'extrasmall',
        },
      },
      a: {
        Component: Link,
        Props: {
          variant: 'smallbuttonunderline',
          target: '_blank',
          'data-id': 'popover-link',
          sx: (theme: ThemeDefinition) => ({
            color: 'textPrimarydark',
            display: 'inline-block',
            marginTop: 'xs',
            fontSize: theme.typography.label.small.fontSize,
            fontWeight: theme.typography.label.smallbold.fontWeight,
          }),
        },
      },
    })}
  </div>
);
