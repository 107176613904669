import { ActionButton } from '@loveholidays/design-system';
import React from 'react';

import { ModalEvent } from '@Components/Modal/useModal';
import { HastNode } from '@Core/types';
import { TooltipHastContent } from '@UX/Tooltip/TooltipHastContent';

interface PopoverContentProps {
  title?: string;
  content?: HastNode;
  setClose?: (event?: ModalEvent | undefined) => any;
}

export const PopoverContent: React.FC<PopoverContentProps> = ({ content, setClose }) => {
  return (
    <div
      data-id="cancellation-popover-content"
      sx={{ padding: 'xs' }}
    >
      {setClose && (
        <ActionButton
          as="button"
          icon="Actions/Cancel"
          type="Icon"
          data-id="popover-close-btn"
          onClick={setClose}
          sx={{
            position: 'absolute',
            right: ['s', '5xs'],
            top: ['s', '3xs'],
            padding: 0,
          }}
        />
      )}
      {content && <TooltipHastContent content={content} />}
    </div>
  );
};
