import { Icon, Icons, IncrementSizes, Label, Popover } from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { Badge } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { PopoverContent } from '@DesignSystemComponents/Search/CancellationPolicyFilter/PopoverContent';

type SearchResultSashProps = Badge & ClassNameProps;

export const SearchResultSash: React.FC<SearchResultSashProps> = ({
  type,
  className,
  icon,
  iconSize,
  color,
  text,
  tooltip,
}) => {
  const content = (
    <div
      data-id={`search-sash-${type}`}
      className={className}
      sx={
        {
          display: 'flex',
          color,
          alignItems: 'center',
        } as SxStyleProp
      }
    >
      <Icon
        name={icon as Icons}
        size={iconSize as keyof IncrementSizes}
        sx={{
          marginRight: '3xs',
        }}
      />
      <Label
        variant="smallbold"
        sx={{
          alignSelf: 'center',
        }}
      >
        {text}
      </Label>
    </div>
  );

  if (!tooltip) {
    return content;
  }

  return (
    <Popover
      trigger="hover"
      content={
        <PopoverContent
          title={text}
          content={tooltip}
        />
      }
    >
      {content}
    </Popover>
  );
};
